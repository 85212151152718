import React, { useEffect } from 'react';
import './App.css';
import { useAuth0 } from "@auth0/auth0-react";

import Login from './page/Login';
import Keystone from './page/Keystone';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Clarity from '@microsoft/clarity';
import posthog from 'posthog-js'

function App() {
  const keystoneTemporaryAccessToken = useSelector((state) => state?.keystone?.temporaryAccessToken);
  const { isAuthenticated, isLoading } = useAuth0();

  const isLocalhost = window.location.origin.includes('localhost') ? true : false;
  const isBotTraffic = /^Mozilla\/5\.0 \(Windows NT 10\.0; Win64; x64\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Chrome\/12.*Safari\/537\.36$/.test(navigator.userAgent);

  if (!isLocalhost && !isBotTraffic) {
    posthog.init('phc_NGGCjf8a2ZLnMRrPhBqOI0pD9UbnTQklPmSsuGNxzEb',
      {
          api_host: 'https://us.i.posthog.com',
          person_profiles: 'always' // or 'identified_only' to not create profiles for anonymous users
      }
    );
  }
  
  return (
    <Router>
      <Routes>
        <Route key={"/:page"} path={"/:page"} element={<Keystone />} />
        <Route key={"/:page/:tabName"} path={"/:page/:tabName"} element={<Keystone />} />
        <Route path="/" element={<>
          { (isAuthenticated || keystoneTemporaryAccessToken) && <Keystone /> }
          { !isLoading && !isAuthenticated && !keystoneTemporaryAccessToken && <Login /> }
        </>} />
      </Routes>
    </Router>
  );
}

export default React.memo(App);