import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const getApiBaseUrl = () => {
    return window.location.origin.includes('local') ? 'http://localhost:8080/keystone' : 'https://api.trymaterialize.com/keystone';
}

const addCall = (call, calls) => {
    const currentTime = new Date().getTime();
    const updatedCalls = calls.filter(item => {
        return (currentTime - item.time < 3000) &&
               !(item.action === call.action && item.type === call.type);
    });

    updatedCalls.push({
        ...call,
        type: call.type,
        action: call.action,
        status: call.status,
        time: new Date().getTime()
    });

    return updatedCalls;
};

export const submitFeedback = createAsyncThunk(
    'keystoneInterface/submitFeedback',
    async ({ accessToken, feedback }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/submit_feedback`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ feedback }),
            });

            if (!response.ok) {
                const data = await response.json();
                return thunkAPI.rejectWithValue(data);
            }

            return;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchPlaidLinkToken = createAsyncThunk(
    'plaid/fetchPlaidLinkToken',
    async({ accessToken }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/plaid/link_token`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                const text = await response.text();
                let data;
                try {
                    data = JSON.parse(text);
                } catch (e) {
                    data = text;
                }
                return thunkAPI.rejectWithValue({
                    status: response.status,
                    data
                });
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const addPlaidAccounts = createAsyncThunk(
    'plaid/addAccounts',
    async({ accessToken, publicToken }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/plaid/add_accounts`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ public_token: publicToken }),
            });

            const data = await response.json();

            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const verifyInviteCode = createAsyncThunk(
    'invitation/verifyInviteCode',
    async ({ code }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/team/invite/verify_code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ invite_code: code }),
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchCompany = createAsyncThunk(
    'company/fetchCompany',
    async ({ accessToken }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/company`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const setCompanyOffersAccepted = createAsyncThunk(
    'company/setCompanyOffersAccepted',
    async ({ accessToken, accepted }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/company/set_flag`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "offers": {
                        "enabled": accepted,
                    },
                }),
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const setUserTosAccepted = createAsyncThunk(
    'user/setUserTosAccepted',
    async ({ accessToken }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/user/set_flag`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "tos": {
                        "accepted": true,
                    },
                }),
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const setUsersOffersNotify = createAsyncThunk(
    'user/setUsersOffersNotify',
    async ({ accessToken, enabled }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/user/set_flag`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "notifications": {
                        "offers": {
                            enabled,
                        }
                    },
                }),
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const setBoxMinimum = createAsyncThunk(
    'company/setBoxMinimum',
    async ({ accessToken, amount }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/company/set_flag`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "box_minimum": {
                        "amount": amount,
                    },
                }),
            });

            const data = await response.json();

            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const setWarehouseLocations = createAsyncThunk(
    'company/setWarehouseLocations',
    async ({ accessToken, warehouse_locations }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/company/set_flag`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    warehouse_locations
                }),
            });

            const data = await response.json();

            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }

            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const uploadInventory = createAsyncThunk(
    'inventory/uploadInventory',
    async ({ accessToken, formData }, thunkAPI) => {
        try {
            let headers = {
                'Authorization': `Bearer ${accessToken}`,
            }
            const response = await fetch(`${getApiBaseUrl()}/inventory/upload`, {
                method: 'POST',
                headers: headers,
                body: formData
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const commitInventory = createAsyncThunk(
    'inventory/commitInventory',
    async ({ accessToken, operationId }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/inventory/commit`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ operation_id: operationId })
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchInventory = createAsyncThunk(
    'inventory/fetchInventory',
    async ({ accessToken }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/inventory`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
    
            if (!response.ok) {
                const text = await response.text();
                let data;
                try {
                    data = JSON.parse(text);
                } catch (e) {
                    data = text;
                }
                return thunkAPI.rejectWithValue({
                    status: response.status,
                    data
                });
            }

            return await response.json();
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchBankAccounts = createAsyncThunk(
    'bank/fetchBankAccounts',
    async ({ accessToken }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/bank_accounts`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteBankAccount = createAsyncThunk(
    'bank/deleteBankAccount',
    async ({ accessToken, id }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/bank_accounts/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchOffers = createAsyncThunk(
    'offers/fetchOffers',
    async ({ accessToken }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/offers`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchTeam = createAsyncThunk(
    'team/fetchTeam',
    async ({ accessToken }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/team`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const respondOffer = createAsyncThunk(
    'offers/respondOffer',
    async ({ accessToken, id, status }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/offers/respond`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id, status })
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchShipments = createAsyncThunk(
    'shipments/fetchShipments',
    async ({ accessToken }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/shipments`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const markShipped = createAsyncThunk(
    'shipments/markShipped',
    async ({ accessToken, id }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/shipments/mark_shipped`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id, status: "SHIPPED" })
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const searchMpn = createAsyncThunk(
    'search/searchMpn',
    async ({ mpn, accessToken }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/search_part`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ mpn }),
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return { ...data, searchTerm: mpn };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const inviteMember = createAsyncThunk(
    'team/inviteMember',
    async ({ accessToken, email, first_name = "", last_name = "", role }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/team/invite`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, first_name, last_name, role }),
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const deleteInvite = createAsyncThunk(
    'team/deleteInvite',
    async ({ accessToken, id }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/team/invite/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const updateRole = createAsyncThunk(
    'team/updateRole',
    async ({ accessToken, user_id, role }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/team/update_role`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id, role }),
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const removeMember = createAsyncThunk(
    'team/removeMember',
    async ({ accessToken, user_id}, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/team/remove_member`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id }),
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchPayouts = createAsyncThunk(
    'payouts/fetchPayouts',
    async ({ accessToken }, thunkAPI) => {
        try {
            const response = await fetch(`${getApiBaseUrl()}/payouts`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                return thunkAPI.rejectWithValue(data);
            }
    
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const assignStateFromCompanyResponse = (state, response) => {
    state.company = response;

    if (response?.flags?.offers?.enabled) {
        state.offersAccepted = true;
    }
    if (response?.has_linked_bank_accounts) {
        state.hasLinkedBank = true;
    }

    if (response?.flags?.warehouse_locations) {
        state.companyWarehouseLocations = response.flags.warehouse_locations;
    }
    if (response?.flags?.box_minimum) {
        state.boxMinimum = response.flags.box_minimum.amount;
    }
};

export const keystoneInterfaceSlice = createSlice({
    name: 'keystoneInterface',
    initialState: {
        selectedObjectId: null,
        isLoggedIn: false,
        offersAccepted: false,
        showModal: false,
        modalType: "upload-flow",
        offersNotify: false,
        magicLinkCode: null,
        inviteCode: null,
        editingWarehouseName: null,
        urlParams: {},
        calls: [],
        offersTabs: ["Active", "Accepted", "Completed", "Rejected"],
        payoutsTabs: ["On Hold", "Sent", "Completed"],
        teamTabs: ["All", "Admins", "Viewers"],
        shipmentsTabs: ["Awaiting Shipment", "Shipped", "Completed"],
        manageTabs: ["Organization", "Warehouses", "Notifications", "Documents"],
        filteredInventoryIds: [],
    },
    reducers: {
        setFile: (state, action) => {
            state.file = action.payload;
        },
        setFileName: (state, action) => {
            state.fileName = action.payload;
        },
        setIsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
        },
        setAccessToken: (state, action) => {
            state.accessToken = action.payload;
        },
        setInviteCode: (state, action) => {
            state.inviteCode = action.payload;
        },
        setUrlParams: (state, action) => {
            state.urlParams = action.payload;
        },
        setPayoutsSetup: (state, action) => {
            state.payoutsSetup = action.payload;
        },
        setAddBankAccount: (state, action) => {
            state.addBankAccount = action.payload;
        },
        setShowModal: (state, action) => {
            state.showModal = action.payload;
        },
        setModalType: (state, action) => {
            state.modalType = action.payload;
        },
        setFeedbackSubmitted: (state, action) => {
            state.feedbackSubmitted = action.payload;
        },
        setFilteredInventoryIds: (state, action) => {
            state.filteredInventoryIds = action.payload;
        },
        setHeaderMappings: (state, action) => {
            state.header_mappings = action.payload;
        },
        setTemporaryProductFound: (state, action) => {  
            state.temporaryProductFound = action.payload;
        },
        setOperationId: (state, action) => {
            state.operation_id = action.payload;
        },
        setUploadingInventory: (state, action) => {
            state.uploadingInventory = action.payload;
        },
        setErrorUploadingInventory: (state, action) => {
            state.errorUploadingInventory = action.payload;
        },
        setUploadedInventory: (state, action) => {
            state.uploadedInventory = action.payload;
        },
        setValidationErrors: (state, action) => {
            state.validation_errors = action.payload;
        },
        setCommittedInventory: (state, action) => {
            state.committedInventory = action.payload;
        },
        setEditingWarehouseName: (state, action) => {
            state.editingWarehouseName = action.payload;
        },
        setSetWarehouseLocationsSuccessfully: (state, action) => {
            state.setWarehouseLocationsSuccessfully = action.payload;
        },
        setErrorSettingWarehouseLocations: (state, action) => {
            state.errorSettingWarehouseLocations = action.payload;
        },
        setSettingWarehouseLocations: (state, action) => {
            state.settingWarehouseLocations = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCompany.pending, (state) => {
                state.fetchingCompany = true;
                state.calls = addCall({
                    type: 'company',
                    action: 'fetch',
                    status: 'pending',
                    user: false
                }, state.calls);
            
                state.errorFetchingCompany = null;
            })
            .addCase(fetchCompany.fulfilled, (state, action) => {
                state.fetchingCompany = false;
                state.calls = addCall({
                    type: 'company',
                    action: 'fetch',
                    status: 'fulfilled',
                    user: false
                }, state.calls);

                state.hasFetchedCompany = true;
                assignStateFromCompanyResponse(state, action.payload);
            })
            .addCase(fetchCompany.rejected, (state, action) => {
                state.fetchingCompany = false;
                state.calls = addCall({
                    type: 'company',
                    action: 'fetch',
                    status: 'rejected',
                    user: false
                }, state.calls);

                state.errorFetchingCompany = action.payload;
                state.hasFetchedCompany = true;
            });
        builder
            .addCase(setUserTosAccepted.pending, (state) => {
                state.settingUserTosAccepted = true;
                state.calls = addCall({
                    type: 'user',
                    action: 'tos accepted',
                    status: 'pending',
                    user: false
                }, state.calls);

                state.errorSettingUserTosAccepted = null;
            })
            .addCase(setUserTosAccepted.fulfilled, (state, action) => {
                state.settingUserTosAccepted = false;
                state.calls = addCall({
                    type: 'user',
                    action: 'tos accepted',
                    status: 'fulfilled',
                    user: false
                }, state.calls);
            })
            .addCase(setUserTosAccepted.rejected, (state, action) => {
                state.settingUserTosAccepted = false;
                state.calls = addCall({
                    type: 'user',
                    action: 'tos accepted',
                    status: 'rejected',
                    user: true,
                    message: action.payload?.user_facing_message
                }, state.calls);

                state.errorSettingUserTosAccepted = action.payload;
            });
        builder
            .addCase(setUsersOffersNotify.pending, (state) => {
                state.settingUserOffersNotifications = true;
                state.calls = addCall({
                    type: 'user',
                    action: 'offers notifications',
                    status: 'pending',
                    user: false
                }, state.calls);

                state.errorSettingUserOffersNotifications = null;
            })
            .addCase(setUsersOffersNotify.fulfilled, (state, action) => {
                state.settingUserOffersNotifications = false;
                state.calls = addCall({
                    type: 'user',
                    action: 'offers notifications',
                    status: 'fulfilled',
                    user: false
                }, state.calls);

                state.offersNotify = action.payload?.flags?.notifications?.offers?.enabled;
            })
            .addCase(setUsersOffersNotify.rejected, (state, action) => {
                state.settingUserOffersNotifications = false;
                state.calls = addCall({
                    type: 'user',
                    action: 'offers notifications',
                    status: 'rejected',
                    user: true,
                    message: action.payload?.user_facing_message
                }, state.calls);

                state.errorSettingUserOffersNotifications = action.payload;
            });
        builder
            .addCase(setWarehouseLocations.pending, (state) => {
                state.setWarehouseLocationsSuccessfully = false;
                state.settingWarehouseLocations = true;
                state.calls = addCall({
                    type: 'company',
                    action: 'warehouse locations updated',
                    status: 'pending',
                    user: false
                }, state.calls);

                state.errorSettingWarehouseLocations = null;
            })
            .addCase(setWarehouseLocations.fulfilled, (state, action) => {
                state.settingWarehouseLocations = false;
                state.calls = addCall({
                    type: 'company',
                    action: 'warehouse locations updated',
                    status: 'fulfilled',
                    user: false
                }, state.calls);
                state.setWarehouseLocationsSuccessfully = true;

                assignStateFromCompanyResponse(state, action.payload);
            })
            .addCase(setWarehouseLocations.rejected, (state, action) => {
                state.settingWarehouseLocations = false;
                state.calls = addCall({
                    type: 'company',
                    action: 'warehouse locations updated',
                    status: 'rejected',
                    user: true,
                    message: action.payload?.user_facing_message
                }, state.calls);

                state.errorSettingWarehouseLocations = action.payload;
                state.setWarehouseLocationsSuccessfully = false;
            });
        builder
            .addCase(setCompanyOffersAccepted.pending, (state) => {
                state.settingCompanyOffersAccepted = true;
                state.calls = addCall({
                    type: 'company',
                    action: 'offers accepted',
                    status: 'pending',
                    user: false
                }, state.calls);

                state.errorSettingCompanyOffersAccepted = null;
            })
            .addCase(setCompanyOffersAccepted.fulfilled, (state, action) => {
                state.settingCompanyOffersAccepted = false;
                state.calls = addCall({
                    type: 'company',
                    action: 'offers accepted',
                    status: 'fulfilled',
                    user: false
                }, state.calls);

                assignStateFromCompanyResponse(state, action.payload);
            })
            .addCase(setCompanyOffersAccepted.rejected, (state, action) => {
                state.settingCompanyOffersAccepted = false;
                state.calls = addCall({
                    type: 'company',
                    action: 'offers accepted',
                    status: 'rejected',
                    user: true,
                    message: action.payload?.user_facing_message
                }, state.calls);

                state.errorSettingCompanyOffersAccepted = action.payload;
            });
        builder
            .addCase(fetchPlaidLinkToken.pending, (state) => {
                state.gettingPlaidLinkToken = true;
                state.calls = addCall({
                    type: 'plaid',
                    action: 'fetch token',
                    status: 'pending',
                    user: false
                }, state.calls);

                state.errorGettingPlaidLinkToken = null;
            })
            .addCase(fetchPlaidLinkToken.fulfilled, (state, action) => {
                state.gettingPlaidLinkToken = false;
                state.calls = addCall({
                    type: 'plaid',
                    action: 'fetch token',
                    status: 'fulfilled',
                    user: false
                }, state.calls);

                state.plaidLinkToken = action.payload.link_token;
            })
            .addCase(fetchPlaidLinkToken.rejected, (state, action) => {
                state.gettingPlaidLinkToken = false;
                state.calls = addCall({
                    type: 'plaid',
                    action: 'fetch token',
                    status: 'rejected',
                    user: false
                }, state.calls);

                if (action.payload?.status === 401) {
                    state.errorGettingPlaidLinkToken = 'Unauthorized';
                } else {
                    state.errorGettingPlaidLinkToken = action.payload.data;
                }
            });
        builder
            .addCase(addPlaidAccounts.pending, (state) => {
                state.addingPlaidAccounts = true;
                state.calls = addCall({
                    type: 'plaid',
                    action: 'add account',
                    status: 'pending',
                    user: false
                }, state.calls);

                state.errorAddingPlaidAccounts = null;
            })
            .addCase(addPlaidAccounts.fulfilled, (state, action) => {
                state.addingPlaidAccounts = false;
                state.calls = addCall({
                    type: 'plaid',
                    action: 'add account',
                    status: 'fulfilled',
                    user: false
                }, state.calls);

                state.plaidAccountsLinked = true;
            })
            .addCase(addPlaidAccounts.rejected, (state, action) => {
                state.addingPlaidAccounts = false;
                state.calls = addCall({
                    type: 'plaid',
                    action: 'add account',
                    status: 'rejected',
                    user: true,
                    message: action.payload?.user_facing_message
                }, state.calls);

                state.errorAddingPlaidAccounts = action.payload;
            });
        builder
            .addCase(verifyInviteCode.pending, (state) => {
                state.calls = addCall({
                    type: 'invitation',
                    action: 'verify',
                    status: 'pending',
                    user: false
                }, state.calls);
            })
            .addCase(verifyInviteCode.fulfilled, (state, action) => {
                state.calls = addCall({
                    type: 'invitation',
                    action: 'verify',
                    status: 'fulfilled',
                    user: false
                }, state.calls);
                state.inviteVerified = action.payload;
            })
            .addCase(verifyInviteCode.rejected, (state, action) => {
                state.calls = addCall({
                    type: 'invitation',
                    action: 'verify',
                    status: 'rejected',
                    user: false
                }, state.calls);

                state.errorVerifyingInviteCode = action.payload;
            });
        builder
            .addCase(setBoxMinimum.pending, (state) => {
                state.settingBoxMinimum = true;
                state.calls = addCall({
                    type: 'company',
                    action: 'box minimum updated',
                    status: 'pending',
                    user: false
                }, state.calls);

                state.errorSettingBoxMinimum = null;
            })
            .addCase(setBoxMinimum.fulfilled, (state, action) => {
                state.settingBoxMinimum = false;
                state.calls = addCall({
                    type: 'company',
                    action: 'box minimum updated',
                    status: 'fulfilled',
                    user: false
                }, state.calls);

                state.boxMinimum = action.payload?.flags?.box_minimum?.amount;
            })
            .addCase(setBoxMinimum.rejected, (state, action) => {
                state.settingBoxMinimum = false;
                state.calls = addCall({
                    type: 'company',
                    action: 'box minimum updated',
                    status: 'rejected',
                    user: true,
                    message: action.payload?.user_facing_message
                }, state.calls);

                state.errorSettingBoxMinimum = action.payload;
            });
        builder
            .addCase(submitFeedback.pending, (state) => {
                state.submittingFeedback = true;
                state.calls = addCall({
                    type: 'feedback',
                    action: 'submit',
                    status: 'pending',
                    user: false
                }, state.calls);

                state.errorSubmittingFeedback = null;
            })
            .addCase(submitFeedback.fulfilled, (state, action) => {
                state.submittingFeedback = false;
                state.calls = addCall({
                    type: 'feedback',
                    action: 'submit',
                    status: 'fulfilled',
                    user: true,
                    message: 'Thank you for the feedback!'
                }, state.calls);

                state.feedbackSubmitted = true;
            })
            .addCase(submitFeedback.rejected, (state, action) => {
                state.submittingFeedback = false;
                state.calls = addCall({
                    type: 'feedback',
                    action: 'submit',
                    status: 'rejected',
                    user: true
                }, state.calls);

                state.errorSubmittingFeedback = action.payload;
            });

        builder
            .addCase(uploadInventory.pending, (state) => {
                state.uploadingInventory = true;
                state.calls = addCall({
                    type: 'inventory',
                    action: 'upload',
                    status: 'pending',
                    user: false
                }, state.calls);
                
                state.errorUploadingInventory = null;
                state.operation_id = null;
                state.validation_errors = null;
                state.header_mappings = null;
                state.uploadedInventory = null;
            })
            .addCase(uploadInventory.fulfilled, (state, action) => {
                state.uploadingInventory = false;
                state.calls = addCall({
                    type: 'inventory',
                    action: 'upload',
                    status: 'fulfilled',
                    user: false
                }, state.calls);
                console.log(action?.payload?.header_mappings);
                state.operation_id = action?.payload?.operation_id;
                state.validation_errors = action?.payload?.validation_errors;
                state.header_mappings = action?.payload?.header_mappings;

                if (action?.payload?.file_data) {
                    state.uploadedInventory = action.payload;
                } else {
                    state.uploadedInventory = null;
                }
            })
            .addCase(uploadInventory.rejected, (state, action) => {
                state.uploadingInventory = false;
                state.calls = addCall({
                    type: 'inventory',
                    action: 'upload',
                    status: 'rejected',
                    user: true,
                    message: action.payload?.user_facing_message
                }, state.calls);

                state.errorUploadingInventory = action.payload;
            });
        builder
            .addCase(commitInventory.pending, (state) => {
                state.committingInventory = true;
                state.calls = addCall({
                    type: 'inventory',
                    action: 'commit',
                    status: 'pending',
                    user: false
                }, state.calls);

                state.errorCommittingInventory = null;
            })
            .addCase(commitInventory.fulfilled, (state, action) => {
                state.committingInventory = false;
                state.calls = addCall({
                    type: 'inventory',
                    action: 'commit',
                    status: 'fulfilled',
                    message: 'Inventory uploaded successfully!',
                    user: true
                }, state.calls);

                state.committedInventory = true;
            })
            .addCase(commitInventory.rejected, (state, action) => {
                state.committingInventory = false;
                state.calls = addCall({
                    type: 'inventory',
                    action: 'commit',
                    status: 'rejected',
                    user: true,
                    message: action.payload?.user_facing_message
                }, state.calls);

                state.errorCommittingInventory = action.payload;
            });
        builder
            .addCase(fetchInventory.pending, (state) => {
                state.fetchingInventory = true;
                state.calls = addCall({
                    type: 'inventory',
                    action: 'fetch',
                    status: 'pending',
                    user: false
                }, state.calls);

                state.errorFetchingInventory = null;
            })
            .addCase(fetchInventory.fulfilled, (state, action) => {
                state.fetchingInventory = false;
                state.calls = addCall({
                    type: 'inventory',
                    action: 'fetch',
                    status: 'fulfilled',
                    user: false
                }, state.calls);
                state.hasFetchedInventory = true;
            })
            .addCase(fetchInventory.rejected, (state, action) => {
                state.fetchingInventory = false;
                state.hasFetchedInventory = true;
                state.calls = addCall({
                    type: 'inventory',
                    action: 'fetch',
                    status: 'rejected',
                    user: false
                }, state.calls);

                if (action.payload.status === 401) {
                    state.errorFetchingInventory = 'Unauthorized';
                } else {
                    state.errorFetchingInventory = action.payload.data;
                }
            });
        builder
            .addCase(searchMpn.pending, (state) => {
                state.searchingMpn = true;
                state.calls = addCall({
                    type: 'item',
                    action: 'search',
                    status: 'pending',
                    user: false
                }, state.calls);

                state.errorSearchingMpn = null;
            })
            .addCase(searchMpn.fulfilled, (state, action) => {
                state.calls = addCall({
                    type: 'item',
                    action: 'search',
                    status: 'fulfilled',
                    user: false
                }, state.calls);
                
                state.searchingMpn = false;
                if (!action.payload.normalized_item) {
                    state.errorSearchingMpn = 'No item found';
                } else {
                    state.temporaryProductFound = true;
                }
            })
            .addCase(searchMpn.rejected, (state, action) => {
                state.searchingMpn = false;
                state.calls = addCall({
                    type: 'item',
                    action: 'search',
                    status: 'rejected',
                    user: false,
                    message: "We're having an issue looking up parts right now, but our team is on it. Please try again later!"
                }, state.calls);

                state.errorSearchingMpn = action.payload;
            });
        builder
            .addCase(fetchOffers.pending, (state) => {
                state.fetchingOffers = true;
                state.calls = addCall({
                    type: 'offers',
                    action: 'fetch',
                    status: 'pending',
                    user: false
                }, state.calls);
            })
            .addCase(fetchOffers.fulfilled, (state, action) => {
                state.fetchingOffers = false;
                state.calls = addCall({
                    type: 'offers',
                    action: 'fetch',
                    status: 'fulfilled',
                    user: false
                }, state.calls);
            })
            .addCase(fetchOffers.rejected, (state, action) => {
                state.fetchingOffers = false;
                state.calls = addCall({
                    type: 'offers',
                    action: 'fetch',
                    status: 'rejected',
                    user: false
                }, state.calls);
            });
        builder
            .addCase(fetchBankAccounts.pending, (state) => {
                state.fetchingBankAccounts = true;
                state.calls = addCall({
                    type: 'accounts',
                    action: 'fetch',
                    status: 'pending',
                    user: false
                }, state.calls);
            })
            .addCase(fetchBankAccounts.fulfilled, (state, action) => {
                state.fetchingBankAccounts = false;
                state.calls = addCall({
                    type: 'accounts',
                    action: 'fetch',
                    status: 'fulfilled',
                    user: false
                }, state.calls);
            })
            .addCase(fetchBankAccounts.rejected, (state, action) => {
                state.fetchingBankAccounts = false;
                state.calls = addCall({
                    type: 'accounts',
                    action: 'fetch',
                    status: 'rejected',
                    user: false
                }, state.calls);
            });
        builder
            .addCase(deleteBankAccount.pending, (state) => {
                state.calls = addCall({
                    type: 'accounts',
                    action: 'delete account',
                    status: 'pending',
                    user: false
                }, state.calls);
            })
            .addCase(deleteBankAccount.fulfilled, (state, action) => {
                state.calls = addCall({
                    type: 'accounts',
                    action: 'delete account',
                    status: 'fulfilled',
                    user: false
                }, state.calls);
            })
            .addCase(deleteBankAccount.rejected, (state, action) => {
                state.calls = addCall({
                    type: 'accounts',
                    action: 'delete account',
                    status: 'rejected',
                    message: action.payload?.user_facing_message,
                    user: true
                }, state.calls);
            });
        builder
            .addCase(respondOffer.pending, (state) => {
                state.calls = addCall({
                    type: 'offer',
                    action: 'respond',
                    status: 'pending',
                    user: false
                }, state.calls);
            })
            .addCase(respondOffer.fulfilled, (state, action) => {
                state.calls = addCall({
                    type: 'offer',
                    action: 'respond',
                    status: 'fulfilled',
                    user: false
                }, state.calls);
            })
            .addCase(respondOffer.rejected, (state, action) => {
                state.calls = addCall({
                    type: 'offer',
                    action: 'respond',
                    status: 'rejected',
                    user: true,
                    message: action.payload?.user_facing_message
                }, state.calls);
            });
        builder
            .addCase(fetchShipments.pending, (state) => {
                state.fetchingShipments = true;
                state.calls = addCall({
                    type: 'shipments',
                    action: 'fetch',
                    status: 'pending',
                    user: false
                }, state.calls);
            })
            .addCase(fetchShipments.fulfilled, (state, action) => {
                state.fetchingShipments = false;
                state.calls = addCall({
                    type: 'shipments',
                    action: 'fetch',
                    status: 'fulfilled',
                    user: false
                }, state.calls);
            })
            .addCase(fetchShipments.rejected, (state, action) => {
                state.fetchingShipments = false;
                state.calls = addCall({
                    type: 'shipments',
                    action: 'fetch',
                    status: 'rejected',
                    user: false
                }, state.calls);
            });
        builder
            .addCase(markShipped.pending, (state) => {
                state.calls = addCall({
                    type: 'shipment',
                    action: 'mark shipped',
                    status: 'pending',
                    user: false
                }, state.calls);
            })
            .addCase(markShipped.fulfilled, (state, action) => {
                state.calls = addCall({
                    type: 'shipment',
                    action: 'mark shipped',
                    status: 'fulfilled',
                    user: false
                }, state.calls);
            })
            .addCase(markShipped.rejected, (state, action) => {
                state.calls = addCall({
                    type: 'shipment',
                    action: 'mark shipped',
                    status: 'rejected',
                    user: true,
                    message: action.payload?.user_facing_message
                }, state.calls);
            });
        builder
            .addCase(fetchPayouts.pending, (state) => {
                state.fetchingPayouts = true;
                state.calls = addCall({
                    type: 'payouts',
                    action: 'fetch',
                    status: 'pending',
                    user: false
                }, state.calls);
            })
            .addCase(fetchPayouts.fulfilled, (state, action) => {
                state.fetchingPayouts = false;
                state.calls = addCall({
                    type: 'payouts',
                    action: 'fetch',
                    status: 'fulfilled',
                    user: false
                }, state.calls);
            })
            .addCase(fetchPayouts.rejected, (state, action) => {
                state.fetchingPayouts = false;
                state.calls = addCall({
                    type: 'payouts',
                    action: 'fetch',
                    status: 'rejected',
                    user: false
                }, state.calls);
            });
        builder
            .addCase(fetchTeam.pending, (state) => {
                state.fetchingTeam = true;
                state.calls = addCall({
                    type: 'team',
                    action: 'fetch',
                    status: 'pending',
                    user: false
                }, state.calls);
            })
            .addCase(fetchTeam.fulfilled, (state, action) => {
                state.fetchingTeam = false;
                state.calls = addCall({
                    type: 'team',
                    action: 'fetch',
                    status: 'fulfilled',
                    user: false
                }, state.calls);
            })
            .addCase(fetchTeam.rejected, (state, action) => {
                state.fetchingTeam = false;
                state.calls = addCall({
                    type: 'team',
                    action: 'fetch',
                    status: 'rejected',
                    user: false
                }, state.calls);
            });
        builder
            .addCase(inviteMember.pending, (state) => {
                state.invitingMember = true;
                state.calls = addCall({
                    type: 'team',
                    action: 'invite member',
                    status: 'pending',
                    user: false
                }, state.calls);
            })
            .addCase(inviteMember.fulfilled, (state, action) => {
                state.invitingMember = false;
                state.calls = addCall({
                    type: 'team',
                    action: 'invite member',
                    status: 'fulfilled',
                    user: false
                }, state.calls);
            })
            .addCase(inviteMember.rejected, (state, action) => {
                state.invitingMember = false;
                state.calls = addCall({
                    type: 'team',
                    action: 'invite member',
                    status: 'rejected',
                    user: true,
                    message: action.payload?.user_facing_message
                }, state.calls);
            });
        builder
            .addCase(deleteInvite.pending, (state) => {
                state.calls = addCall({
                    type: 'team',
                    action: 'delete invite',
                    status: 'pending',
                    user: false
                }, state.calls);
            })
            .addCase(deleteInvite.fulfilled, (state, action) => {
                state.calls = addCall({
                    type: 'team',
                    action: 'delete invite',
                    status: 'fulfilled',
                    user: false
                }, state.calls);
            })
            .addCase(deleteInvite.rejected, (state, action) => {
                state.calls = addCall({
                    type: 'team',
                    action: 'delete invite',
                    status: 'rejected',
                    user: true,
                    message: action.payload?.user_facing_message
                }, state.calls);
            });
        builder
            .addCase(updateRole.pending, (state) => {
                state.calls = addCall({
                    type: 'team',
                    action: 'update role',
                    status: 'pending',
                    user: false
                }, state.calls);
            })
            .addCase(updateRole.fulfilled, (state, action) => {
                state.calls = addCall({
                    type: 'team',
                    action: 'update role',
                    status: 'fulfilled',
                    user: false
                }, state.calls);
            })
            .addCase(updateRole.rejected, (state, action) => {
                state.calls = addCall({
                    type: 'team',
                    action: 'update role',
                    status: 'rejected',
                    user: true,
                    message: action.payload?.user_facing_message
                }, state.calls);
            });
        builder
            .addCase(removeMember.pending, (state) => {
                state.calls = addCall({
                    type: 'team',
                    action: 'remove member',
                    status: 'pending',
                    user: false
                }, state.calls);
            })
            .addCase(removeMember.fulfilled, (state, action) => {
                state.calls = addCall({
                    type: 'team',
                    action: 'remove member',
                    status: 'fulfilled',
                    user: false
                }, state.calls);
            })
            .addCase(removeMember.rejected, (state, action) => {
                state.calls = addCall({
                    type: 'team',
                    action: 'remove member',
                    status: 'rejected',
                    user: true,
                    message: action.payload?.user_facing_message
                }, state.calls);
            });
    },
});

export const normalizeTabName = (tabName) => {
    return tabName?.toLowerCase().replaceAll(' ', '-');
}

export const { setFile } = keystoneInterfaceSlice.actions;
export const { setFileName } = keystoneInterfaceSlice.actions;
export const { setIsLoggedIn } = keystoneInterfaceSlice.actions;
export const { setTosAccepted } = keystoneInterfaceSlice.actions;
export const { setAccessToken } = keystoneInterfaceSlice.actions;
export const { setInviteCode } = keystoneInterfaceSlice.actions;
export const { setUrlParams } = keystoneInterfaceSlice.actions;
export const { setPayoutsSetup } = keystoneInterfaceSlice.actions;
export const { setAddBankAccount } = keystoneInterfaceSlice.actions;
export const { setShowModal } = keystoneInterfaceSlice.actions;
export const { setModalType } = keystoneInterfaceSlice.actions;
export const { setFeedbackSubmitted } = keystoneInterfaceSlice.actions;
export const { setFilteredInventoryIds } = keystoneInterfaceSlice.actions;
export const { setHeaderMappings } = keystoneInterfaceSlice.actions;
export const { setTemporaryProductFound } = keystoneInterfaceSlice.actions;
export const { setOperationId } = keystoneInterfaceSlice.actions;
export const { setUploadingInventory } = keystoneInterfaceSlice.actions;
export const { setErrorUploadingInventory } = keystoneInterfaceSlice.actions;
export const { setUploadedInventory } = keystoneInterfaceSlice.actions;
export const { setValidationErrors } = keystoneInterfaceSlice.actions;
export const { setCommittedInventory } = keystoneInterfaceSlice.actions;
export const { setEditingWarehouseName } = keystoneInterfaceSlice.actions;
export const { setSetWarehouseLocationsSuccessfully } = keystoneInterfaceSlice.actions;
export const { setErrorSettingWarehouseLocations } = keystoneInterfaceSlice.actions;
export const { setSettingWarehouseLocations } = keystoneInterfaceSlice.actions;

export default keystoneInterfaceSlice.reducer;