import './style/FileManager.css';
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { } from '../../redux/keystoneSlice';
import Label from './Label';
import HeaderVisual from './HeaderVisual';
import Button from './Button';
import { setErrorUploadingInventory } from '../../redux/keystoneInterfaceSlice';
import Icon from './Icon';
import UploadIcon from '../../media/upload-file-icon.png';

const FileManager = ({ handleFileSelection, file, setFile }) => {
    const [fileName, setFileName] = useState(null);
    const keystoneProductData = useSelector((state) => state?.keystone?.productData);

    const [dropHover, setDropHover] = useState(false);
    const [fileInputKey, setFileInputKey] = useState(0);
    const dispatch = useDispatch();

    const displayDropZone = !file;

    const handleFileChange = (event) => {
        if (event.target.files.length > 0) {
            console.log("File selected: ", event.target.files[0]);
            let validExtensions = ['csv', 'xlsx', 'tsv'];
            if (!validExtensions.includes(event.target.files?.[0]?.name?.split('.').pop())) {
                // Clear the input.
                setFileInputKey(fileInputKey + 1);
                dispatch(setErrorUploadingInventory("INVALID_FILE_TYPE"));
                return;
            }
            setFile(event.target.files[0]);
            setFileName(event.target.files[0].name);

            handleFileSelection({
                file: event.target.files[0],
                fileName: event.target.files[0].name
            });

            // Clear the input.
            setFileInputKey(fileInputKey + 1);
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const file = event.dataTransfer.files[0];
        if (file) {
            console.log(file);
            handleFileChange({ target: { files: [file] } });
        }
        setDropHover(false);

    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDropHover(true);
    };

    const handleDragEnter = (event) => {
        event.preventDefault();
        event.stopPropagation();
        event.dataTransfer.dropEffect = 'copy';
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setDropHover(false);
    };

    return (
        <div className={`keystone-file-manager ${displayDropZone && 'cta-drop-wrapper'} product-vertical-container v-gap-l ${keystoneProductData && "cta"} ${dropHover ? "hover" : ""}`} onClick={!file ? () => document.getElementById("file-upload").click() : null}>
            <div 
                className={`${displayDropZone ? 'product-vertical-container drop-zone pad-xl align-center' : 'product-horizontal-container justify-center align-center'} v-gap-s h-gap-s ${dropHover ? "hover" : ""}`}
                onDrop={displayDropZone ? handleDrop : null} 
                onDragOver={displayDropZone ? handleDragOver : null}
                onDragLeave={displayDropZone ? handleDragLeave : null}
                onDragEnter={displayDropZone ? handleDragEnter : null}>
                {file &&
                    <div id="uploaded-file-wrapper">
                        <Label size='s' bold={true} displayFont={true} content={fileName} />
                    </div>
                }

                {!file && <Icon icon={UploadIcon} color="black" size="48" />}
                <p className={`center-text size-m bold line-m mobile-hide`}>
                    {!file && "Drag and drop or "}
                    <label htmlFor="file-upload" className="file-label">
                        {!file && "select to upload"}
                        <input 
                            type="file"
                            id="file-upload" 
                            className="file-input" 
                            accept=".csv, .xlsx, .tsv" 
                            onChange={handleFileChange}
                            key={fileInputKey} />
                    </label>
                    {file && <Button customId={"replace-file-button"} label={"Replace File"} secondary={true} onClick={() => document.getElementById("file-upload").click()} /> }
                    {!file && " your excess inventory."}
                </p>
                <div className="product-vertical-container desktop-hide">
                    <Label content="Upload your excess inventory." displayFont={true} bold={true} center={true} />
                </div>
                {!file && <p className="sf-mono size-s">(Supported file types: .csv, .tsv, .xlsx)</p>}
            </div>
            {/* <div className="product-vertical-container desktop-hide">
                <label id="file-upload-input-wrapper" htmlFor="file-upload" className="file-label">
                    <Label content={file ? "Replace File" : "Upload File"} displayFont={true} />
                    <input 
                        type="file"
                        id="file-upload" 
                        className="file-input" 
                        accept=".csv, .xlsx, .tsv" 
                        onChange={handleFileChange}
                        key={fileInputKey} />
                </label>
            </div> */}
        </div>
    );
};

export default React.memo(FileManager);