import './style/UploadFlow.css';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import HeaderVisual from './HeaderVisual';
import CellPreview from './CellPreview';
import Button from './Button';
import Input from './Input';
import Icon from './Icon';
import PrivacyOffers from '../../media/privacy-offers@3x.png';
import Dollar from '../../media/dollar-circle@3x.png';
import ShippingBox from '../../media/shipping-box@3x.png';
import RectangleSparkle from '../../media/rectangle-sparkle@3x.png';
import Secure from '../../media/secure-48@3x.png';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setShowModal, setOperationId, setUploadedInventory, setErrorUploadingInventory, setValidationErrors } from '../../redux/keystoneInterfaceSlice';
import { selectAllInventoryItemsWithData } from '../../redux/keystoneInventorySlice';
import { searchMpn, commitInventory, uploadInventory } from '../../redux/keystoneInterfaceSlice';
import Label from './Label';
import FileManager from './FileManager';
import AppIcon from '../../media/app-icon.png';
import CloseIcon from '../../media/close@3x.png';
import Divider from './Divider';
import SimpleLoader from './SimpleLoader';
import _ from 'lodash';
import SheetIcon from '../../media/sheet-logo.svg';
import DownArrowIcon from '../../media/down-arrow@3x.png';
import ChipRounded from './ChipRounded';
import Wordmark from '../../media/materialize-logo-wordmark-black.png';

const UploadFlow = ({ isUpdate = false, handleDismiss = null }) => {
    // TODO: User is still showing undefined briefly
    const dispatch = useDispatch();
    const keystoneInventoryAll = useSelector(selectAllInventoryItemsWithData);

    const keystoneUser = useSelector((state) => state?.keystone?.user);
    const keystoneInterfaceAccessToken = useSelector((state) => state?.keystoneInterface?.accessToken);
    const keystoneInterfaceModalType = useSelector((state) => state?.keystoneInterface?.modalType);
    const keystoneInterfaceUploadedInventory = useSelector((state) => state?.keystoneInterface?.uploadedInventory);
    const keystoneInterfaceUploadingInventory = useSelector((state) => state?.keystoneInterface?.uploadingInventory);
    const keystoneInterfaceCommittingInventory = useSelector((state) => state?.keystoneInterface?.committingInventory);
    const keystoneInterfaceCommittedInventory = useSelector((state) => state?.keystoneInterface?.committedInventory);
    const keystoneInterfaceErrorUploadingInventory = useSelector((state) => state?.keystoneInterface?.errorUploadingInventory);
    const keystoneInterfaceValidationErrors = useSelector((state) => state?.keystoneInterface?.validation_errors);
    const keystoneInterfaceOperationId = useSelector((state) => state?.keystoneInterface?.operation_id);
    const keystoneInterfaceHeaderMappings = useSelector((state) => state?.keystoneInterface?.header_mappings);

    const [hasReviewedHeaderMappings, setHasReviewedHeaderMappings] = useState(false);

    const operationHeadersChanged = keystoneInterfaceUploadedInventory?.header_mappings &&
         !_.isEqual(keystoneInterfaceUploadedInventory?.header_mappings, keystoneInterfaceHeaderMappings);

    const [file, setFile] = useState(null);

    const [headerData, setHeaderData] = useState([]);
    const [, setRowsData] = useState([]);
    const searchInputRef = useRef(null);
    const [searchValue, setSearchValue] = useState('');
    const [, setSearching] = useState(false);
    const [step, setStep] = useState(keystoneInterfaceModalType?.includes("upload-flow-file") ? 1 : 0);
    const [requestId, setRequestId] = useState(null);

    const uploadInventoryData = useCallback((file, headerMappings) => {
        setFile(file);
        dispatch(setErrorUploadingInventory(null));

        const formData = new FormData();
        formData.append('file', file);
        if (headerMappings) {
            formData.append('header_mappings', new Blob([JSON.stringify(headerMappings)], { type: "application/json" }));
        }
        setRequestId(_.uniqueId());
        dispatch(uploadInventory({ accessToken: keystoneInterfaceAccessToken, formData }));
    }, [dispatch, keystoneInterfaceAccessToken])

    const handleNewFile = useCallback((file) => {
        setHasReviewedHeaderMappings(false);
        uploadInventoryData(file);
    }, [uploadInventoryData]);

    useEffect(() => {
        if (keystoneInterfaceCommittedInventory) {
            if (handleDismiss) {
                handleDismiss();
            } else {
                dispatch(setShowModal(false));
            }
        }
    }, [keystoneInterfaceCommittedInventory, handleDismiss, dispatch]);

    const columnTooltips = {
        "Unique ID": "Internal identifier that does not change.",
        "Original Manufacturer": "Original equipment manufacturer (OEM).",
        "Part Number": "Original manufacturer's part number.",
        "Description": "Details about the item.",
        "Quantity": "Amount on hand at location.",
        "Warehouse Name": "Unique name for where the item is stored.",
        "Unit Value": "Current item value on books.",
        "Bin Location": "Name of physical location inside warehouse.",
        "Condition": "Condition the specific items are in. If left out it assumed all items are new and in original box",
        "Quantity Type": "How the quantity is measured (units, weight, feet, etc.)",
    }

    const requiredColumns = ["Unique ID", "Original Manufacturer", "Part Number", "Description", "Quantity", "Warehouse Name", "Unit Value"];
    const optionalColumns = ["Bin Location", "Condition", "Quantity Type"];
    
    const reset = useCallback(() => {
        setFile(null);
        setHasReviewedHeaderMappings(false);
        dispatch(setOperationId(null));
        dispatch(setUploadedInventory(null));
        dispatch(setValidationErrors(null));
    }, [dispatch]);

    useEffect(() => {
        if (keystoneInterfaceUploadedInventory) {
            setHeaderData(keystoneInterfaceUploadedInventory?.file_data?.sheets?.[0]?.parsed_header_row?.data);
            setRowsData(keystoneInterfaceUploadedInventory?.file_data?.sheets?.[0]?.rows);
        }
    }, [keystoneInterfaceUploadedInventory]);

    useEffect(() => {
        if (keystoneInterfaceErrorUploadingInventory) {
            reset();
        }
    }, [reset, keystoneInterfaceErrorUploadingInventory]);

    function summarizeErrorsAsArray(data) {
        const errorMap = {};
    
        data.forEach(item => {
            item.errors.forEach(error => {
                const { error_code, field } = error;
                
                if (!errorMap[error_code]) {
                    errorMap[error_code] = {
                        count: 0,
                        fields: new Set()
                    };
                }
    
                errorMap[error_code].count++;
                errorMap[error_code].fields.add(field);
            });
        });
    
        // Convert the errorMap into an array of objects
        const summarizedErrors = Object.entries(errorMap).map(([error_code, info]) => ({
            message: error_code,
            count: info.count,
            fields: Array.from(info.fields).sort()  // Convert Set to array for fields
        }));
    
        return summarizedErrors;
    }

    const uniqueErrors = keystoneInterfaceValidationErrors?.length > 0 ? summarizeErrorsAsArray(keystoneInterfaceValidationErrors) : [];
    const tooManyErrors = keystoneInterfaceValidationErrors?.length > 4;
    const headerErrors = keystoneInterfaceValidationErrors?.filter(error => !error.row_number)?.[0]?.errors || [];
    const fileHasDuplicateColumns = headerErrors.some(error => error.error_code?.includes("DUPLICATE_HEADER"));
    const rowErrorsWithData = keystoneInterfaceValidationErrors?.filter(error => error.row_number !== null).map(error => {
        const rowData = keystoneInterfaceUploadedInventory?.file_data?.sheets?.find(sheet => sheet.name === error.sheet_name)?.rows?.find(item => item.row_number === error.row_number + 1)?.data;
        return {
            ...error,
            rowData
        };
    }); 

    const searchInputChange = (e) => {
        setSearchValue(e.target.value);
    };

    const searchPart = () => {
        setSearching(true);

        dispatch(searchMpn({ accessToken: keystoneInterfaceAccessToken, mpn: searchValue }));
    };

    const handleCommitInventory = () => {
        dispatch(commitInventory({ accessToken: keystoneInterfaceAccessToken, operationId: keystoneInterfaceOperationId }));
    };

    const operationSummaryChangedRows = 
        (keystoneInterfaceUploadedInventory?.operation_summary?.added_items?.length || 0) + 
        (keystoneInterfaceUploadedInventory?.operation_summary?.updated_items?.length || 0) +
        (keystoneInterfaceUploadedInventory?.operation_summary?.deleted_items?.length || 0);

    const firstTimeUploading = !isUpdate || keystoneInventoryAll?.length === 0 
        || (keystoneInventoryAll?.length === 1 && keystoneInventoryAll?.[0]?.id === 'MTLRZ-KTT-SR-123');

    let stepOneHeaderTitle = !firstTimeUploading ? "Update your inventory list" : "Upload your unneeded inventory list";
    let stepOneHeaderSubtitle = !firstTimeUploading ? "Stay up-to-date by uploading a new snapshot of your unneeded inventory.": "You're moments away from understanding the value of your unneeded inventory.";
    
    if (keystoneInterfaceErrorUploadingInventory) {
        if (keystoneInterfaceErrorUploadingInventory === "INVALID_FILE_TYPE") {
            stepOneHeaderTitle = "Sorry, we can't process that file type yet!";
        } else {
            stepOneHeaderTitle = "Whoops - something went wrong";
        }
    } else if (keystoneInterfaceUploadingInventory) {
        stepOneHeaderTitle = "Uploading your inventory";
    } else if ((keystoneInterfaceHeaderMappings && !hasReviewedHeaderMappings && operationSummaryChangedRows === 0 && file)) {
        stepOneHeaderTitle = "Review your header links";
    } else if (tooManyErrors || headerErrors?.length > 0 || rowErrorsWithData?.length > 0 ||(hasReviewedHeaderMappings && operationSummaryChangedRows === 0)) {
        stepOneHeaderTitle = "Issues with uploaded data";
    } else if (operationSummaryChangedRows !== 0) {
        stepOneHeaderTitle = "Summary of changes";
    }

    if (keystoneInterfaceErrorUploadingInventory) {
        if (keystoneInterfaceErrorUploadingInventory === "INVALID_FILE_TYPE") {
            stepOneHeaderSubtitle = "Please choose a .csv, .xlsx, or .tsv file and try again.";
        } else {
            stepOneHeaderSubtitle = "Don't worry - we've let engineering know. Please try again.";
        }
    } else if (keystoneInterfaceUploadingInventory) {
        stepOneHeaderSubtitle = "Please wait while we process your file.";
    } else if (fileHasDuplicateColumns) {
        stepOneHeaderSubtitle = `The column "${headerErrors.find(error => error.error_code?.includes("DUPLICATE_HEADER"))?.field}" exists more than once in the uploaded file. Please reupload the file with only one of each column!`;
    } else if ((keystoneInterfaceHeaderMappings && !hasReviewedHeaderMappings && operationSummaryChangedRows === 0) && file) {
        stepOneHeaderSubtitle = "Match your headers to our system to ensure accuracy.";
    } else if (tooManyErrors || headerErrors?.length > 0 || rowErrorsWithData?.length > 0 || (hasReviewedHeaderMappings && operationSummaryChangedRows === 0)) {
        stepOneHeaderSubtitle = "Please review the issues and fix them or upload a new file.";
    } else if (operationSummaryChangedRows !== 0) {
        const addedRows = keystoneInterfaceUploadedInventory?.operation_summary?.added_items?.length || 0;
        const updatedRows = keystoneInterfaceUploadedInventory?.operation_summary?.updated_items?.length || 0;
        const deletedRows = keystoneInterfaceUploadedInventory?.operation_summary?.deleted_items?.length || 0;
        stepOneHeaderSubtitle = (<div className="product-horizontal-container h-gap-xs">
            <div className="product-horizontal-container h-gap-xs">
                <Label content={(<>
                    Adding {addedRows ? (<em>{addedRows}</em>) : addedRows} items, updating {updatedRows ? (<em>{updatedRows}</em>) : updatedRows} items, and removing {deletedRows ? (<em>{deletedRows}</em>) : deletedRows} items.
                </>)} size="l" bold={true} displayFont={true}/>
            </div>
        </div>);
    }

    const downloadFile = useCallback(async () => {
        const accessToken = keystoneInterfaceAccessToken;
        const fileUrl = `http://localhost:8080/keystone/inventory/last_upload`; 
        
        try {
            const response = await fetch(fileUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
            });

            if (!response.ok) {
            throw new Error('Network response was not ok');
            }

            const reader = response.body.getReader();
            
            const stream = new ReadableStream({
            start(controller) {
                function push() {
                reader.read().then(({ done, value }) => {
                    if (done) {
                    controller.close();
                    return;
                    }
                    controller.enqueue(value);
                    push();
                });
                }
                
                push();
            }
            });

            const dlUrl = URL.createObjectURL(await new Response(stream).blob());

            const link = document.createElement('a');
            link.href = dlUrl;

            const contentDisposition = response.headers.get('content-disposition');
            let filename = 'Materialize - Last Uploaded File'; // Fallback filename
            
            if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename="(.+)"/) || contentDisposition.match(/filename=([^;]+)/);
            if (filenameMatch && filenameMatch.length > 1) {
                filename = `Materialize - ${filenameMatch[1]}`;
            }
            }

            link.setAttribute('download', filename);

            document.body.appendChild(link);
            link.click();
            link.remove();

            window.URL.revokeObjectURL(dlUrl);

        } catch (error) {
            console.error('Streaming download failed:', error);
        }
    }, [keystoneInterfaceAccessToken]);

    const uniqueIdInformationComponent = isUpdate ? 'Match items with their existing IDs to add new items, update current ones, and remove those not in the latest snapshot.'
     : 'Make sure to use consistent Unique IDs for each line item to ensure accurate list updates in the future.';

    let bottomButtonContent;

    // No file, start at beginingg.
    // With file, if you are uploading, no bottom buttons.
    // With file, if you have reviewed header mappings and have errors, show error buttons.
    // With file, if you have you reviewed header mappings and have no errors, invalid state.
    // With file, if you have not reviewed header mappings, show review buttons.

    const invalidMappings = Object.keys(keystoneInterfaceHeaderMappings || {})?.some(k => requiredColumns.includes(k) && !keystoneInterfaceHeaderMappings[k]);
    const confirmChangesButton = (
        <Button onClick={() => { setHasReviewedHeaderMappings(true); uploadInventoryData(file, keystoneInterfaceHeaderMappings); }} label="Confirm Changes" brand={true} large={true} disabled={
            _.isEqual(keystoneInterfaceHeaderMappings, keystoneInterfaceUploadedInventory?.header_mappings) || invalidMappings
        } />
    );

    if (!file && isUpdate && !firstTimeUploading) {
        bottomButtonContent = (
            <div className="product-horizontal-container h-gap-xl justify-center">
                <Button secondary={true} image={SheetIcon} label="View Template" onClick={() => {}} />
                <Button secondary={true} icon={DownArrowIcon} iconColor={'black'} label="Get Last Upload" onClick={() => downloadFile()} />
            </div>
        );
    } else if (!file && (!isUpdate || firstTimeUploading)) {
        bottomButtonContent = null;
    } else if (keystoneInterfaceUploadingInventory) {
        bottomButtonContent = null;
    } else if (operationHeadersChanged || invalidMappings) {
        bottomButtonContent = (
            <div className="product-horizontal-container h-gap-s flex-1 space-between">
                <Button onClick={reset} label="Back" secondary={true}/>
                {confirmChangesButton}
            </div>
        );
    } else if (!operationHeadersChanged && keystoneInterfaceOperationId && operationSummaryChangedRows > 0) {
        bottomButtonContent = (
            <div className="product-horizontal-container h-gap-s flex-1 space-between">
                <Button onClick={reset} label="Back" secondary={true}/>
                <Button onClick={() => handleCommitInventory()} label="Complete Upload" brand={true} large={true} disabled={invalidMappings} loading={keystoneInterfaceCommittingInventory}/>
            </div>
        );
    } else if (!hasReviewedHeaderMappings && !operationHeadersChanged && operationSummaryChangedRows === 0) {
        bottomButtonContent = (
            <div className="product-horizontal-container h-gap-s flex-1 space-between">
                <Button onClick={reset} label="Back" secondary={true}/>
                <Button onClick={() => setHasReviewedHeaderMappings(true)} label="Complete Upload" brand={true} large={true} loading={keystoneInterfaceCommittingInventory}/>
            </div>
        );
    } else if (!hasReviewedHeaderMappings && (headerErrors?.length > 0 || rowErrorsWithData?.length > 0)) {
        bottomButtonContent = (
            <div className="product-horizontal-container h-gap-s flex-1 space-between">
                <Button onClick={reset} label="Back" secondary={true}/>
                {confirmChangesButton}
            </div>
        );
    } else if (hasReviewedHeaderMappings) {
        bottomButtonContent = (
            <div className="product-horizontal-container h-gap-s flex-1 space-between">
                <Button onClick={() => setHasReviewedHeaderMappings(false)} label="Back" secondary={true}/>
                <Button onClick={() => document.getElementById("file-upload").click()} label="Replace File" brand={true} large={true} loading={keystoneInterfaceCommittingInventory}/>
            </div>
        );
    }

    const mapEntries = Object.entries(keystoneInterfaceHeaderMappings || {});
    const headerSelectionComponent = mapEntries?.length ? 
        <div className="product-vertical-container v-gap-m flex-1 space-between">
            <div className="product-vertical-container v-gap-s">
                <Label content={`Mapped Headers`} size="l" bold={true} displayFont={true} />
                    {mapEntries?.map(([k,v]) => (
                        <HeaderVisual
                            key={`${requestId}${k}${headerData}`}
                            label={k}
                            value={v}
                            originalMappedValue={keystoneInterfaceUploadedInventory?.header_mappings?.[k]}
                            tooltip={columnTooltips[k]}
                            fancy={false}
                            status={headerErrors?.some(e => e.field === k)  
                                    ? "negative" :
                                    v ? "positive" : "neutral"}
                            horizontal={true}
                            headerData={headerData} />))}
            </div>
        </div> : null;

    function formatAndEmphasizeWithOxfordComma(fields) {
        // Handle the edge case where there are no fields
        if (fields.length === 0) {
            return null;
        }

        // Map through the fields and wrap them with <em> tags
        const formattedFields = fields.map((field, index) => (
            // Use a React fragment key to avoid warning in lists
            <React.Fragment key={index}>
            <em>{field}</em>
            </React.Fragment>
        ));

        // Determine how to join the fields based on their count
        if (fields.length === 1) {
            return formattedFields;
        }

        if (fields.length === 2) {
            return [formattedFields[0], ' and ', formattedFields[1]];
        }

        // For more than 2 fields, apply the Oxford comma before the last item
        const lastField = formattedFields.pop();
        return [...formattedFields.flatMap((field) => [field, ', ']), 'and ', lastField];
    }

    const circleNumber = (number) => (
        <Label content={number} displayFont={true} bold={true} size="xl" style={{
            width: "32px",
            height: "32px",
            minWidth: "32px",
            minHeight: "32px",
            borderRadius: "50%",
            color: "var(--black)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderColor: "var(--black)",
            borderWidth: "2px",
            borderStyle: "solid",
        }} />
    );

    const requiredColumn = (columnName, columnDetails) => (
        <div className="product-horizontal-container h-gap-m">
            <div className="product-horizontal-container flex-1 align-center h-gap-m">
                <Label content={columnName} size="s" bold={true} mono={true} style={{minWidth: "max-content"}} />
                <Divider />
            </div>
            <Label content={columnDetails} size="s" secondary={true} bold={true} displayFont={true} style={{flex: "2"}} />
        </div>
    );

    const requiredColumnNew = (columnName, columnDetails) => (
        <HeaderVisual label={columnName} tooltip={columnDetails} />
    );

    const stageOneDragAndDropSummaryContent = (
        <div className="product-vertical-container v-gap-xl flex-1">
            <div className="product-vertical-container v-gap-l flex-1">
                <div className="product-horizontal-container h-gap-l align-center space-between">
                    <div className="product-horizontal-container h-gap-l align-center">
                        {circleNumber(1)}
                        <div className="product-vertical-container v-gap-xs">
                            <Label content="Prepare your list for upload." displayFont={true} bold={true} size="l" />
                            {/* <Label content="We require the following columns:" size="s" secondary={true} bold={true} displayFont={true} /> */}
                        </div>
                    </div>
                    <Button customId={'upload-flow-view-template'} secondary={true} image={SheetIcon} label="Example Template" onClick={() => window.open('https://docs.google.com/spreadsheets/d/1uvMZt23k8Co0WIlhuJH53F6lYHBxQgakOG-uq0XiPlo/edit?usp=drive_link', '_blank', 'noopener,noreferrer')} />
                </div>
                <div className="product-vertical-container v-gap-s">
                    <Label content="Required Columns" classes="red-text" size="m" bold={true} displayFont={true} />
                    <div className="product-horizontal-container flex-wrap h-gap-s v-gap-s">
                        {requiredColumns.map(column => requiredColumnNew(column, columnTooltips[column]))}
                    </div>
                </div>
                <div className="product-vertical-container v-gap-s">
                    <Label content="Optional Columns" size="m" bold={true} displayFont={true} />
                    <div className="product-horizontal-container flex-wrap h-gap-s v-gap-s">
                        {optionalColumns.map(column => requiredColumnNew(column, columnTooltips[column]))}
                        {requiredColumnNew("Other Columns", "Any additional columns will be ignored.")}
                    </div>
                </div>
            </div>
            <Divider />
            <div className="product-horizontal-container h-gap-l align-center space-between">
                <div className="product-horizontal-container h-gap-l align-center">
                    {circleNumber(2)}
                    <div className="product-vertical-container v-gap-xs">
                        <Label content="Upload your file." displayFont={true} bold={true} size="l" />
                        {/* <Label content="Upload a .csv, .xlsx, or .tsv file." size="s" secondary={true} bold={true} displayFont={true} /> */}
                    </div>
                </div>
                {/* <div className="product-horizontal-container green-bg round-s pad-xs v-gap-xs align-center h-gap-m">
                    <Icon icon={Secure} color="black" size="24" />
                    <div className="product-vertical-container v-gap-xs">
                        <Label content="Secure Upload" size="s" displayFont={true} bold={true} />
                        <p className="size-s sf-mono"><a href="https://trymaterialize.com/privacy-policy" className="visited-black" target='_blank' rel='noreferrer'>See how we protect your data</a>.</p>
                    </div>
                </div> */}
            </div>
        </div>
    );
    

    return (
        <div id="welcome-overlay" className="product-horizontal-container v-gap-xxl h-gap-xl">
            {step === 0 && !isUpdate && (
                <div className="upload-promo-container product-vertical-container flex-1 h-gap-l pad-xl align-center">
                    <div id="wordmark-wrapper" className="product-vertical-container pad-s">
                        <img src={Wordmark} alt="Materialize Wordmark" className="wordmark-inline" onClick={() => window.location.href = "/"} />
                    </div>
                    <div className="product-vertical-container flex-1 justify-center v-gap-m">
                        <div id="welcome-header" className="product-vertical-container align-center center-text v-gap-s no-pad-bot pad-l">
                            <div className="product-vertical-container pad-s no-pad-bot v-gap-xs">
                                <Label content={`Welcome in, ${keystoneUser?.first_name}!`} size="xl" bold={true} displayFont={true} />
                                <Label content={`We automate the unavoidable problem of unneeded assets at manufacturing companies like ${keystoneUser?.company?.name}.`} size="m" bold={true} secondary={true} displayFont={true} />
                            </div>
                        </div>
                        <div id="value-props-wrapper" className="product-horizontal-container flex-wrap">
                            <div className="product-horizontal-container width-50 pad-xs">
                                <div className="black-bg round-m pad-m product-vertical-container v-gap-m width-50">
                                    <Icon icon={RectangleSparkle} color="rgb(255, 221, 87)" size="32" />
                                    <div className="product-vertical-container v-gap-xs">
                                        <Label content="Market Analysis" size="m" bold={true} color="white" displayFont={true} />
                                        <Label content="For every item without fees for transparency." color="white" size="s" line="s" mono={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="product-horizontal-container width-50 pad-xs">
                                <div className="black-bg round-m pad-m product-vertical-container v-gap-m width-50">
                                    <Icon icon={PrivacyOffers} color="rgb(255, 221, 87)" size="32" />
                                    <div className="product-vertical-container v-gap-xs">
                                        <Label content="Private Offers" size="m" bold={true} color="white" displayFont={true} />
                                        <Label content="From our network of 140+ sales channels." color="white" size="s" line="s" mono={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="product-horizontal-container width-50 pad-xs">
                                <div className="black-bg round-m pad-m product-vertical-container v-gap-m width-50">
                                    <Icon icon={ShippingBox} color="rgb(255, 221, 87)" size="32" />
                                    <div className="product-vertical-container v-gap-xs">
                                        <Label content="Prepaid Shipping" size="m" bold={true} color="white" displayFont={true} />
                                        <Label content="Included without fees for any accepted offer." color="white" size="s" line="s" mono={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="product-horizontal-container width-50 pad-xs">
                                <div className="black-bg round-m pad-m product-vertical-container v-gap-m width-50">
                                    <Icon icon={Dollar} color="rgb(255, 221, 87)" size="32" />
                                    <div className="product-vertical-container v-gap-xs">
                                        <Label content="Instant Payouts" size="m" bold={true} color="white" displayFont={true} />
                                        <Label content="Upon receipt of goods by the buyer." color="white" size="s" line="s" mono={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="product-vertical-container v-gap-m justify-center pad-xl no-pad-top">
                            <Label content="Our platform is free to use, with no hidden fees or costs. We take 16% of the final sale price, which includes all fees and shipping costs." size="s" bold={true} secondary={true} center={true} displayFont={true} />
                        </div>
                    </div>
                    <div className="product-horizontal-container h-gap-m">
                        <Button label="Learn More" onClick={() => window.open('https://trymaterialize.com', '_blank', 'noopener,noreferrer')} secondary={true} large={true} />

                        <Button label="Get Started" onClick={() => setStep(1)} brand={true} large={true} />
                    </div>
                </div>
            )}
            {(step === 1 || isUpdate) && (
                <div className={`product-vertical-container v-gap-xl pad-xl align-center justify-center`}>
                    <div id="welcome-header" className="product-vertical-container align-center center-text v-gap-s pad-l no-pad-bot">
                        { isUpdate && <Button customId={'close-button'} icon={CloseIcon} iconSize={36} onClick={handleDismiss} />}
                        <div className="product-vertical-container pad-s no-pad-bot v-gap-xs">
                            <Label content={stepOneHeaderTitle} shakeOnShow={keystoneInterfaceErrorUploadingInventory} size="xl" bold={true} displayFont={true} negative={fileHasDuplicateColumns || (hasReviewedHeaderMappings && (headerErrors?.length || rowErrorsWithData?.length)) || keystoneInterfaceErrorUploadingInventory} />
                            <Label content={stepOneHeaderSubtitle} size="m" bold={true} secondary={true} displayFont={true} />
                        </div>
                    </div>
                    <div className={`keystone-upload-flow modal-wrapper product-vertical-container pad-xl v-gap-xl`}>
                        {!file && isUpdate && !firstTimeUploading && stageOneDragAndDropSummaryContent}
                        {!file && (!isUpdate || firstTimeUploading) && (
                            <div className="product-horizontal-container h-gap-s align-end">
                                {stageOneDragAndDropSummaryContent}
                            </div>
                        )}
                        <FileManager file={file} setFile={setFile} handleFileSelection={({file}) => handleNewFile(file)} />
                        {keystoneInterfaceUploadingInventory && (
                            <div className={`product-vertical-container pad-xl relative`}>
                                <SimpleLoader loading={true} />
                            </div>
                        )}
                        {keystoneInterfaceUploadedInventory?.header_mappings && (!hasReviewedHeaderMappings || operationSummaryChangedRows > 0) && headerSelectionComponent}
                        {hasReviewedHeaderMappings && keystoneInterfaceOperationId && operationSummaryChangedRows === 0 && ( 
                            <div className={`product-vertical-container v-gap-s pad-b-s`}>
                                <Label content="No changes detected" size="l" bold={true} displayFont={true} />
                                <Label content="Please upload a new file to update your inventory." size="s" bold={true} displayFont={true} secondary={true} />
                            </div>
                        )}
                        {hasReviewedHeaderMappings && tooManyErrors && uniqueErrors?.length > 0 &&
                            <div className={`product-vertical-container v-gap-s`}>
                                <Label content='Data Issues' size="l" bold={true} displayFont={true} />
                                <div className="product-vertical-container v-gap-xs">
                                    {uniqueErrors?.map((error, index) => (
                                        (() => {

                                            let message ;
                                            const pluralLocations = error.count > 1;
                                            const pluralFields = error.fields?.length > 1;
                                            const emphasizedFields = error.fields?.map(e => (<em>{e}</em>));
                                            
                                            const joinedFields = formatAndEmphasizeWithOxfordComma(emphasizedFields);

                                            switch (error.message) {
                                                case "INVALID_NUMBER":
                                                    message = (<>Invalid number in <em>{error.count}</em> location{pluralLocations ? 's':''} for field{pluralFields ? 's':''} {joinedFields}.</>);
                                                    break;
                                                case "NEGATIVE_NUMBER":
                                                    message = (<>Negative number in <em>{error.count}</em> location{pluralLocations ? 's':''} for field{pluralFields ? 's':''} {joinedFields}.</>);
                                                    break;
                                                case "MISSING_REQUIRED_FIELD":
                                                    message = (<>Missing value in <em>{error.count}</em> location{pluralLocations ? 's':''} for field{pluralFields ? 's':''} {joinedFields}.</>);
                                                    break;
                                                default:
                                                    message = error.message;
                                            }
                                            return <div key={`${requestId}${error.fields?.join(',')}${error.message}`}>
                                                <Label content={message} size="s" bold={true} displayFont={true} secondary={true} bullet={true}/>
                                            </div>
                                        })()
                                ))}
                                </div>
                            </div>
                        }
                        {/* {settingHeaderMappingsForTooManyErrors && headerSelectionComponent} */}
                        {(file
                            && hasReviewedHeaderMappings
                            && !tooManyErrors
                            && (rowErrorsWithData?.length > 0) 
                            && !fileHasDuplicateColumns)
                        && (
                            <div id="review-container" className="product-vertical-container v-gap-xxl">
                                {rowErrorsWithData && rowErrorsWithData.length > 0 && (
                                    <div className="product-vertical-container v-gap-s">
                                        <Label content="The following rows have issues:" size="m" bold={true} displayFont={true} />
                                        <div className="product-vertical-container v-gap-l">
                                            {rowErrorsWithData.map((errorParent, index) => (
                                                <div key={`${errorParent?.row_number}${errorParent?.errors?.[0]?.error_code}`} className={`product-vertical-container v-gap-s`}>
                                                    <div className={`product-horizontal-container align-center space-between h-gap-l`}>
                                                        <div className='flex flex-1'>
                                                            <Label content={`Row ${errorParent.row_number}:`} size="s" bold={true} mono={true} uppercase={true} secondary={true} />
                                                        </div>
                                                        {errorParent.errors?.[0].message && (
                                                            <ChipRounded small={true} label={errorParent.errors?.[0].error_code} negative={true} />
                                                        )}
                                                        <div className='flex flex-1'/>
                                                    </div>
                                                    <div className="product-horizontal-container row-error h-gap-m">
                                                        <div className="product-horizontal-container v-gap-xs flex-1">
                                                        {[errorParent.errors?.[0]].map((error, index) => {
                                                            const prevColumnName = headerData?.[headerData?.indexOf(keystoneInterfaceUploadedInventory?.header_mappings?.[error.field]) - 1];
                                                            const prevColumnValue = errorParent.rowData?.[headerData.indexOf(keystoneInterfaceUploadedInventory?.header_mappings?.[error.field])-1];
                                                            const nextColumnName = headerData?.[headerData?.indexOf(keystoneInterfaceUploadedInventory?.header_mappings?.[error.field]) + 1];
                                                            const nextColumnValue = errorParent.rowData?.[headerData.indexOf(keystoneInterfaceUploadedInventory?.header_mappings?.[error.field])+1];
                                                            return (
                                                            <>
                                                                {prevColumnName && <CellPreview
                                                                    columnName={prevColumnName}
                                                                    value={prevColumnValue}
                                                                    last={false}
                                                                    first={true}
                                                                    blurFadeDirection={'left'}/>}
                                                                <CellPreview 
                                                                    columnName={keystoneInterfaceUploadedInventory?.header_mappings?.[error.field]}
                                                                    value={errorParent.rowData?.[headerData.indexOf(keystoneInterfaceUploadedInventory?.header_mappings?.[error.field])]}
                                                                    primary={true}
                                                                    last={!nextColumnName}
                                                                    first={!prevColumnName}
                                                                />
                                                                {nextColumnName && <CellPreview
                                                                    columnName={nextColumnName}
                                                                    value={nextColumnValue}
                                                                    last={true}
                                                                    first={false}
                                                                    blurFadeDirection={'right'}/>}
                                                            </>);
                                                        })}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {!!bottomButtonContent && <div className="product-horizontal-container h-gap-m justify-center align-center">
                            {bottomButtonContent}
                        </div>}
                        {/* {!isUpdate && keystoneInventoryAll?.length <= 0 && !file && <Divider label="or"/>}
                        {!isUpdate && keystoneInventoryAll?.length <= 0 && !file && <div className="keystone-upload-flow product-vertical-container v-gap-xl">
                            <div className="product-vertical-container v-gap-s">
                                <div className="product-vertical-container keystone-card round-8 pad-m h-gap-m v-gap-m">
                                    <Label content="Check out our tool with any part number!" size="m" bold={true} displayFont={true} />
                                    <div className="product-horizontal-container mobile-vertical flex-1 width-100 h-gap-m v-gap-m">
                                        <Input placeholder="Enter a part number" onChange={searchInputChange} inputRef={searchInputRef} flex={true} large={true} />
                                        <Button customId="keystone-search-part" label="Kick the tires" onClick={searchPart} disabled={searchValue === ''} brand={true} large={true} />
                                    </div>
                                </div>
                            </div>
                        </div>} */}
                    </div>
                    {!file && (
                        <div className="product-horizontal-container modal-width green-bg pad-m round-m v-gap-xs align-center h-gap-m">
                            <Icon icon={Secure} color="black" size="48" />
                            <div className="product-vertical-container v-gap-xs">
                                <Label content="Secure Upload" size="m" bold={true} displayFont={true} />
                                <p className="size-s sf-mono">We take privacy seriously. Learn how we keep your organization's data safe and secure <a href="https://trymaterialize.com/privacy-policy" className="visited-black" target='_blank' rel='noreferrer'>here</a>.</p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default React.memo(UploadFlow);